import './index.scss';
import Initializer from 'lib/initializer';
import time from 'lib/time';

Initializer.registerComponent('site/coral_active_conversation', function(element, data) {

  window.coralActiveConversationsLoaded = function(coralData) {
    var stories = coralData.stories || [];
    var injectHtml = '';
    if (stories.length > 0) {
      var domain = new URL(stories[0].url).hostname.toString().replace('www.', '');
      for (var i = 0; i<stories.length; i++) {
        var story = stories[i];
        injectHtml += `<li>
          <a href="${story.url}" data-analytics-viewport="active_conversation" data-analytics-link="active_conversation">
            ${story.title}
          </a>
          <span class="c-coral-active-conversation__meta">
            ${time.recentRelative(story.publishedAt)}
            <span class="comment_count">
              <a href="${story.url}#comments" data-analytics-link="active_conversation:comment_count">${story.count}</a>
            </span>
          </span>
        </li>`;
      }
      var querySelector = `[data-coral-active-conversation][data-community-domain="${domain}"] ol`;
      var ol = document.querySelector(querySelector);
      ol.insertAdjacentHTML('beforeend', injectHtml);
    } else {
      document.querySelector(`[data-coral-active-conversation][data-community-domain="${domain}"] .c-coral-active-conversation__body`).insertAdjacentHTML('beforeend', '<p>No recent comments!</p>');
    }
  };

  var coralScript = document.createElement('script');
  coralScript.src = data.jsonp_url;
  element.appendChild(coralScript);
}, {
  priority: Initializer.HIGH
});
