import './index.scss';
import Initializer from 'lib/initializer';
import time from 'lib/time';

Initializer.registerComponent('sidebar_rocks/coral_active_conversation', function(element, data) {

  window.coralActiveConversationsLoaded = function(coralData) {
    var stories = coralData.stories || [];
    var injectHtml = '';
    if (stories.length > 0) {
      for (var i = 0; i<stories.length; i++) {
        var story = stories[i];
        injectHtml += `<li>
          <a href="${story.url}" data-analytics-viewport="active_conversation" data-analytics-link="active_conversation">
            ${story.title}
          </a>
          <span class="c-rock-coral-active-conversation__meta">
            ${time.recentRelative(story.publishedAt)}
            <span class="comment_count">
              <a href="${story.url}#comments" data-analytics-link="active_conversation:comment_count">${story.count}</a>
            </span>
          </span>
        </li>`;
      }
      var ol = document.querySelector('#coral-active-conversation ol');
      ol.insertAdjacentHTML('beforeend', injectHtml);
    } else {
      document.querySelector('#coral-active-conversation .c-rock-list__body').insertAdjacentHTML('beforeend', '<p>No recent comments!</p>');
    }
    window.coralActiveConversationsLoaded = null;
  };

  var coralScript = document.createElement('script');
  coralScript.src = data.jsonp_url;
  element.appendChild(coralScript);
}, {
  priority: Initializer.HIGH
});
